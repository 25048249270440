// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import { html } from 'lit';

if (!(globalThis as any).URLPattern) {
  await import("urlpattern-polyfill");
}

import { Router } from '@thepassle/app-tools/router.js';
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js';

// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';

import './pages/app-home.js';

const baseURL: string = (import.meta as any).env.BASE_URL;

const scrollToTop = {
  name: 'scrollToTop',
  afterNavigation: () => {
    window.scrollTo(0, 0);
  }
}
export const router = new Router({
    plugins: [
      scrollToTop,
    ],
    routes: [
      {
        path: resolveRouterPath(),
        title: 'Home',
        icon: html`
          <svg width="20" height="20" viewBox="0 0 33 33" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.9656 16.7955C32.8383 17.0751 32.6218 17.2149 32.3162 17.2149H28.8779V32.3137C28.8779 32.517 28.8142 32.6823 28.6868 32.8094C28.5595 32.9365 28.394 33 28.1902 33H21.3136C21.1099 33 20.9443 32.9365 20.817 32.8094C20.6896 32.6823 20.626 32.517 20.626 32.3137V20.6464H12.374V32.3137C12.374 32.517 12.3104 32.6823 12.183 32.8094C12.0557 32.9365 11.8901 33 11.6864 33H4.80979C4.60604 33 4.4405 32.9365 4.31315 32.8094C4.18581 32.6823 4.12213 32.517 4.12213 32.3137V17.2149H0.683839C0.378213 17.2149 0.161727 17.0751 0.034383 16.7955C-0.0420236 16.5159 0.0089141 16.2617 0.187196 16.0329L16.0034 0.247833C16.3345 -0.0826109 16.6655 -0.0826109 16.9966 0.247833L32.8128 16.0329C32.9911 16.2617 33.042 16.5159 32.9656 16.7955Z" />
          </svg>`,
        render: () => html`<app-home></app-home>`
      },
      {
        path: resolveRouterPath('rep'),
        title: 'Rep',
        icon: html`
          <svg width="20" height="19" viewBox="0 0 37 36" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.7031 15.0457V13.9465C32.7031 6.256 26.3315 0 18.5 0C10.6685 0 4.29689 6.256 4.29689 13.9465V15.0461C1.79009 15.9572 0 18.3187 0 21.0967C0 24.6632 2.94428 27.5543 6.57638 27.5543H8.95141C9.57211 27.5543 10.0752 27.0602 10.0752 26.4503V15.7432C10.0752 15.1337 9.57211 14.6397 8.95141 14.6397H7.86432V13.9465C7.86432 8.18788 12.6359 3.50297 18.5 3.50297C24.3641 3.50297 29.1357 8.18788 29.1357 13.9465V14.6397H28.0486C27.4274 14.6397 26.9243 15.1337 26.9243 15.7432V26.4503C26.9243 27.0602 27.4274 27.5543 28.0486 27.5543H28.5689C27.4367 30.0981 24.9402 31.9217 21.9925 32.1391C21.8313 31.3964 21.1606 30.8389 20.3562 30.8389H16.6433C15.7189 30.8389 14.9693 31.5753 14.9693 32.4831V34.3563C14.9693 35.264 15.7189 36 16.6433 36H20.3562C21.1822 36 21.8636 35.4122 22.0018 34.6406C26.3922 34.3933 30.0616 31.4633 31.3113 27.4884C34.5226 27.0622 37.0005 24.3669 37.0005 21.0972C37.0005 18.3192 35.2094 15.9577 32.7036 15.0466L32.7031 15.0457Z" />
          </svg>`,
        plugins: [
          lazy(() => import('./pages/app-about/app-about.js')),
        ],
        render: () => html`<app-about></app-about>`
      },
      {
        path: resolveRouterPath('rep/:userloc'),
        title: 'Rep',
        plugins: [
          lazy(() => import('./pages/app-about/app-about.js')),
        ],
        render: ({params}) => html`<app-about .userloc=${params.userloc}></app-about>`
      },
      {
        path: resolveRouterPath('products'),
        title: 'Products',
        icon: html`
          <svg width="20" height="20" viewBox="0 0 29 29" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.8993 8.4919C28.9664 8.64853 29 8.83873 29 9.0625V27.1875C29 27.6798 28.821 28.1049 28.463 28.463C28.1049 28.821 27.6798 29 27.1875 29H1.8125C1.32022 29 0.895062 28.821 0.537037 28.463C0.179012 28.1049 0 27.6798 0 27.1875V9.0625C0 8.83873 0.0335648 8.64853 0.100694 8.4919L2.51736 1.2419C2.62924 0.883873 2.84182 0.592978 3.15509 0.369213C3.49074 0.123071 3.84877 0 4.22917 0H24.7708C25.1512 0 25.4981 0.123071 25.8113 0.369213C26.147 0.592978 26.3708 0.883873 26.4826 1.2419L28.8993 8.4919ZM3.72569 9.0625H25.2743L23.4618 3.625H5.53819L3.72569 9.0625Z" />
          </svg>
        `,
        plugins: [
          lazy(() => import('./pages/app-products/app-products.js')),
        ],
        render: () => html`<app-products></app-products>`
      },
      {
        path: resolveRouterPath('service'),
        title: 'Service',
        icon: html`
          <svg width="20" height="20" viewBox="0 0 31 31" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.76008 29.841L19.2136 17.3866C19.2572 17.343 19.3206 17.3281 19.3791 17.3474C22.4533 18.3369 25.9566 17.6204 28.3971 15.1798C30.7666 12.8101 31.5173 9.43587 30.6534 6.42801C30.6105 6.27874 30.4171 6.23209 30.3076 6.34218L26.1911 10.459C26.1513 10.4988 26.0922 10.5144 26.0381 10.4994L21.7934 9.31583C21.7405 9.30091 21.6989 9.25986 21.6846 9.20699L20.501 4.96264C20.4861 4.90791 20.501 4.84944 20.5414 4.80964L24.658 0.692795C24.7681 0.582705 24.7214 0.389894 24.5722 0.346977C21.5645 -0.517566 18.1905 0.233156 15.821 2.60287C13.3805 5.0435 12.6641 8.54707 13.6536 11.6215C13.6722 11.68 13.6579 11.7434 13.6144 11.7869L1.15959 24.2401C-0.38653 25.7863 -0.38653 28.2941 1.15959 29.8403C2.70571 31.3866 5.21396 31.3872 6.76008 29.841Z" />
          </svg>
        `,
        plugins: [
          lazy(() => import('./pages/app-service/app-service.js')),
        ],
        render: () => html`<app-service></app-service>`
      },
      {
        path: resolveRouterPath('videos'),
        title: 'Videos',
        icon: html`
          <svg width="20" height="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
              <path d="M16,0C7.16,0,0,7.16,0,16s7.16,16,16,16,16-7.16,16-16S24.84,0,16,0ZM22,16.87l-9,5.2c-.67.38-1.5-.1-1.5-.87v-10.39c0-.77.83-1.25,1.5-.87l9,5.2c.67.38.67,1.35,0,1.73Z"/>
          </svg>
        `,
        plugins: [
          lazy(() => import('./pages/app-videos/app-videos.js')),
        ],
        render: () => html`<app-videos></app-videos>`
      },
      {
        path: resolveRouterPath('videos/:pid'),
        title: ({params}) => `${params.pid} Videos`,
        plugins: [
          lazy(() => import('./pages/playlist-overview/playlist-overview.js')),
        ],
        render: ({params}) => html`<playlist-overview .pid=${params.pid}></playlist-overview>`
      },
      {
        path: resolveRouterPath('article/:aid'),
        title: ({params}) => `Article`,
        plugins: [
          lazy(() => import('./pages/article-overview/article-overview.js')),
        ],
        render: ({params}) => html`<article-overview .aid=${params.aid}></article-overview>`
      },
      {
        path: resolveRouterPath('products/calculator'),
        title: 'Calculator',
        plugins: [
          lazy(() => import('./pages/product-calculator/product-calculator.js')),
        ],
        render: () => html`<product-calculator></product-calculator>`
      },
      {
        path: resolveRouterPath('desktop-calculator'),
        title: 'Calculator',
        plugins: [
          lazy(() => import('./pages/desktop-calculator/desktop-calculator.js')),
        ],
        render: () => html`<desktop-calculator></desktop-calculator>`
      },
      {
        path: resolveRouterPath('products/:catid'),
        title: ({params}) => `Category ${params.catid}`,
        plugins: [
          lazy(() => import('./pages/product-category/product-category.js')),
        ],
        render: ({params}) => html`<product-category .catid=${params.catid}></product-category>`
      },
      {
        path: resolveRouterPath('products/:catid/:subid'),
        title: ({params}) => `Sub-Category ${params.subid}`,
        plugins: [
          lazy(() => import('./pages/product-sub-category/product-sub-category.js')),
        ],
        render: ({params}) => html`<product-sub-category .catid=${params.catid} .subid=${params.subid}></product-sub-category>`
      },
      {
        path: resolveRouterPath('products/:catid/:subid/:pid'),
        title: ({params}) => `Product ${params.pid}`,
        plugins: [
          lazy(() => import('./pages/product-overview/product-overview.js')),
        ],
        render: ({params}) => html`<product-overview .pid=${params.pid}></product-overview>`
      },
      {
        path: resolveRouterPath('service/errors'),
        title: 'Errors',
        plugins: [
          lazy(() => import('./pages/app-errors/app-errors.js')),
        ],
        render: () => html`<app-errors></app-errors>`
      },
      {
        path: resolveRouterPath('service/errors/:slug'),
        title: ({params}) => `Error ${params.slug}`,
        plugins: [
          lazy(() => import('./pages/error-overview/error-overview.js')),
        ],
        render: ({params}) => html`<error-overview .slug=${params.slug}></error-overview>`
      },
      {
        path: resolveRouterPath('service/guides'),
        title: 'Guides',
        plugins: [
          lazy(() => import('./pages/app-guides/app-guides.js')),
        ],
        render: () => html`<app-guides></app-guides>`
      },
      {
        path: resolveRouterPath('service/contact'),
        title: 'Contact',
        plugins: [
          lazy(() => import('./pages/app-contact/app-contact.js')),
        ],
        render: () => html`<app-contact></app-contact>`
      }
    ]
  });

  // This function will resolve a path with whatever Base URL was passed to the vite build process.
  // Use of this function throughout the starter is not required, but highly recommended, especially if you plan to use GitHub Pages to deploy.
  // If no arg is passed to this function, it will return the base URL.

  export function resolveRouterPath(unresolvedPath?: string) {
    var resolvedPath = baseURL;
    if(unresolvedPath) {
      resolvedPath = resolvedPath + unresolvedPath;
    }

    return resolvedPath;
  }
