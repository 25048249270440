import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import {router} from "../router";

@customElement('app-install')
export class AppInstall extends LitElement {

  static styles = css`
  .jl-title {
    text-transform: uppercase;
  }
  .install__overlay {
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.2s ease-in;
    width: 100vw;
    z-index: 999999;
    display: none;
  }
  .install__container {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(250, 250, 250, 0.8);
    border-radius: 10px;
    bottom: 0;
    color: black;
    filter: brightness(1.1);
    left: 0;
    margin: 0 8px 10px;
    overflow: hidden;
    position: fixed;
    transform: translateY(calc(100% + 10px));
    transition: transform 0.4s cubic-bezier(0.4, 0.24, 0.3, 1);
    width: calc(100vw - 16px);
    z-index: 999999;
  }
  .install__header {
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-width: 0.5px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 13px 16px;
  }
  .install__title {
    color: #333;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.125;
    margin: 0;
    padding: 0;
  }
  .install__cancel {
    color: #2d7cf6;
    font-size: 16px;
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
  }
  .install__body {
    display: flex;
    width: 100%;
  }
    .install__content {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      border-width: 0.5px;
      color: inherit;
      margin: 0 16px;
      padding: 16px;
      width: 100%;
    }
    .install__description {
      color: #7b7b7a;
      font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      font-size: 13px;
      line-height: 17px;
      margin: 0;
      padding: 0;
    }
  .install__steps {
    color: inherit;
    margin: 0 16px;
    padding: 16px;
  }
    .install__step {
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      text-align: left;
      margin-bottom: 16px;
    }
    .install__step:last-of-type {
      margin-bottom: 0;
    }
    .install__step-icon {
      flex: 0 0 auto;
      height: 30px;
      margin-right: 32px;
      width: 25px;
    }
    .install__step-icon.share {
      color: #2d7cf6;
      fill: #2d7cf6;
    }
    .install__step-copy {
      color: #7b7b7a;
      font-weight: 600;
      font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      font-size: 13px;
      line-height: 17px;
      margin: 0;
      padding: 0;
    }
    
    .install.active .install__overlay {
      opacity: 1;
      display: block;
    }
    .install.active .install__container {
      transform: translateY(0);
      display: block;
    }
    .install__button {
      display: block;
      user-select: none;
      position: relative;
      width: fit-content;
      min-width: 128px;
      font-size: 16px;
      font-weight: 600;
      hyphens: auto;
      line-height: 1.1;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      background: #CC2229;
      border-style: solid;
      border-width: 2px;
      border-color: #CC2229;
      border-radius: 10px;
      padding: 8px 32px 9px !important;
      color: #FFFFFF;
      margin: 0 auto;
    }
  `;

  static properties = {
    device: { type: Object },
    showPrompt: { type: Boolean },
  };

  connectedCallback() {
    super.connectedCallback();

    this.showPrompt = false;

    if(!this.device) {
      this.getDevice();
    }
  }
  isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  isAndroid = () => /android/i.test(navigator.userAgent);
  isMacOS = () => /Macintosh|Mac|Mac OS|MacIntel|MacPPC|Mac68K/gi.test(navigator.userAgent);
  isWindows = () => /Win32|Win64|Windows|Windows NT|WinCE/gi.test(navigator.userAgent);
  isChromeOS = () => /CrOS/gi.test(navigator.userAgent);
  getBrowser = () => {
    const {userAgent} = navigator;

    return userAgent.match(/edg/i) ? 'edge' :
        userAgent.match(/chrome|chromium|crios/i) ? 'chrome' :
            userAgent.match(/firefox|fxios/i) ? 'firefox' :
                userAgent.match(/safari/i) ? 'safari' :
                    userAgent.match(/opr\//i) ? 'opera' :
                        userAgent.match(/android/i) ? 'android' :
                            userAgent.match(/iphone/i) ? 'iphone' : 'unknown';
  }
  getPlatform = () => {
    return this.isIOS() ? 'ios' :
        this.isAndroid() ? 'android' :
            this.isMacOS() ? 'macos' :
                this.isChromeOS() ? 'chromeos' :
                    this.isWindows() ? 'windows' : 'unknown';
  }

  shouldShowPrompt() {
    if (this.device.isValidOS !== undefined && this.device.isStandalone === false && !installShown && (this.device.device === 'ios' || this.device.device === 'android')) {
      //const aboveMinVisits = numberOfVisits + 1 >= promptOnVisit;
      //const belowMaxVisits = numberOfVisits + 1 < promptOnVisit + timesToShow;

      setTimeout(() => {
        this.showPrompt = true;
        installShown = true;
      }, 1000);
    }
  }

  closePrompt() {
    this.showPrompt = false;
  }

  getDevice() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const platformRegex = new RegExp("(iphone|ipad|ipod|macintosh)", "g");
    const versionRegex = new RegExp("os (\\d+)", "g");

    const platform = platformRegex.exec(userAgent)?.[1];
    const isIpad =
        platform === "macintosh" && window.navigator.maxTouchPoints > 1;
    const version = versionRegex.exec(userAgent)?.[1];
    const standalone =
        "standalone" in window.navigator && !!window.navigator.standalone;
    const browser = this.getBrowser();
    const device = this.getPlatform();

    this.device = {
      isStandalone: standalone,
      isValidOS: isIpad || !!(platform && platform !== "macintosh"),
      platform: isIpad ? "ipad" : platform ?? null,
      version: !!platform || isIpad ? version ?? null : null,
      browser: browser,
      device: device
    };

    console.log(this.device);

    this.shouldShowPrompt();
  }

  handleInstall() {
    deferredPrompt.prompt();
  }

  render() {
      return html`
        <div class="install ${this.showPrompt ? 'active' : ''}">
          <div class="install__overlay" @click=${this.closePrompt}></div>
          <div class="install__container">
            <div class="install__header">
              <p id="pwa-prompt-title" class="install__title">Install the <span class="jl-title">Julabo</span> USA App</p>
              <button class="install__cancel" @click=${this.closePrompt}>Cancel</button>
            </div>
            <div class="install__body">
              <div class="install__content">
                <p id="pwa-prompt-description" class="install__description">Add the app to your home screen for anytime, anywhere access to key <span class="jl-title">Julabo</span> USA products, support and news.</p>
              </div>
            </div>
            <div class="install__steps">
              ${this.device.isValidOS ? html`
                <div class="install__step">
                  <svg xmlns="http://www.w3.org/2000/svg" class="install__step-icon share" viewBox="0 0 120 169">
                    <g fill="currentColor">
                      <path d="M60 0l28 28-2 2a586 586 0 0 0-4 4L64 15v90h-8V15L38 34l-4-4-2-2L60 0z"></path>
                      <path d="M0 49h44v8H8v104h104V57H76v-8h44v120H0V49z"></path>
                    </g>
                  </svg>
                  <p class="install__step-copy">1) Press the 'Share' button on the menu bar below.</p>
                </div>
                <div class="install__step">
                  <svg xmlns="http://www.w3.org/2000/svg" class="install__step-icon"
                       viewBox="55.99425507 31.98999977 157.76574707 157.76371765">
                    <path fill="#58595b"
                          d="M90.49 32.83a54.6 54.6 0 019.55-.84c23.98.03 47.96 0 71.94.01 8.5.07 17.3 1.74 24.4 6.65 10.94 7.28 16.52 20.54 17.35 33.3.06 26.03 0 52.06.03 78.08 0 10.16-3.59 20.56-10.95 27.73-7.93 7.61-18.94 11.43-29.79 11.98-25.71.03-51.42 0-77.12.01-10.37-.11-21.01-3.77-28.17-11.48-8.22-8.9-11.72-21.29-11.73-33.21.01-23.03-.03-46.05.02-69.07-.01-9.14 1.33-18.71 6.65-26.4 6.21-9.4 16.97-14.79 27.82-16.76m38.18 41.09c-.05 10.25.01 20.5 0 30.75-9.58-.03-19.16.02-28.75-.04-2.27.08-4.98-.25-6.68 1.61-2.84 2.34-2.75 7.12.01 9.48 1.8 1.69 4.46 1.57 6.75 1.64 9.56-.04 19.12-.01 28.67-.03.02 10.24-.06 20.48.01 30.72-.14 2.66 1.36 5.4 3.95 6.3 3.66 1.66 8.52-1.13 8.61-5.23.26-10.59.02-21.2.09-31.79 9.88 0 19.76.02 29.64.01 2.74.12 5.85-.67 7.14-3.34 2.23-3.75-.61-9.34-5.08-9.29-10.57-.14-21.14-.01-31.7-.04-.01-10.25.04-20.49 0-30.74.3-3.5-2.66-7.09-6.3-6.79-3.65-.33-6.66 3.26-6.36 6.78z"></path>
                    <path fill="transparent"
                          d="M128.67 73.92c-.3-3.52 2.71-7.11 6.36-6.78 3.64-.3 6.6 3.29 6.3 6.79.04 10.25-.01 20.49 0 30.74 10.56.03 21.13-.1 31.7.04 4.47-.05 7.31 5.54 5.08 9.29-1.29 2.67-4.4 3.46-7.14 3.34-9.88.01-19.76-.01-29.64-.01-.07 10.59.17 21.2-.09 31.79-.09 4.1-4.95 6.89-8.61 5.23-2.59-.9-4.09-3.64-3.95-6.3-.07-10.24.01-20.48-.01-30.72-9.55.02-19.11-.01-28.67.03-2.29-.07-4.95.05-6.75-1.64-2.76-2.36-2.85-7.14-.01-9.48 1.7-1.86 4.41-1.53 6.68-1.61 9.59.06 19.17.01 28.75.04.01-10.25-.05-20.5 0-30.75z"></path>
                  </svg>
                  <p class="install__step-copy">2) Press 'Add to Home Screen'.</p>
                </div>
              ` : html`
                <div class="install__step">
                  <button @click=${this.handleInstall} class="install__button jl-button"><span class="jl-button__content">Install Now</span></button>
                </div>
              `}
            </div>
          </div>
          <div>
      `;
  }
}
